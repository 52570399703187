import styled from 'styled-components';
import useTimeWindow from '~/hooks/useTimeWindow';
import Box from '~/components/Box';
import Typography from '~/components/Typography';
import StyledEllipsisText from './StyledEllipsisText';

const AddressInfo = ({ data: { station, timeWindow }, taskCompleted }: any) => {
	const timeText = useTimeWindow(timeWindow, false);

	return (
		<Box
			alignItems='start'
			style={{ marginTop: '20px', flexDirection: 'column' }}
		>
			<StyledEllipsisText
				variant='h6'
				color={taskCompleted ? 'black20' : 'primary'}
			>
				{station?.name}
			</StyledEllipsisText>
			{!taskCompleted && (
				<>
					<StyledEllipsisText variant='subtitle2'>
						{station?.address}
					</StyledEllipsisText>
					<StyledContentText variant='h6'>{timeText}</StyledContentText>
				</>
			)}
		</Box>
	);
};

const StyledContentText = styled(Typography)`
	margin-top: 8px;
	color: ${({ theme }) => theme.colors.black60};
`;

export default AddressInfo;
